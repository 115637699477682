/* stylelint-disable no-invalid-position-at-import-rule */
@media screen and (min-width: 0) {
    @import 'small/small';

    .no-mobile {
        display: none;
    }

    #mediaquery::after {
        content: 'small';
        display: none;
    }
}

@media screen and (min-width: @large-breakpoint) {
    @import 'large/large';

    .mobile {
        display: none !important;
    }

    .no-mobile {
        display: block;
    }

    // Enable some accordions in mobile only
    .toggle-target.mobile-only {
        display: block;
    }

    #mediaquery::after {
        content: 'large';
    }
}

@import 'print';
/* stylelint-enable no-invalid-position-at-import-rule */
